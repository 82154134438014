.box {
  margin-top: 40px;
  margin-bottom: 40px;
}

.files {
  width: 95%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}



