@media only screen and (min-width: 500px) {
  .cardWidth {
    width: 200px;
    height: 120px;
  }

  .cardContent {
    display: block;
    width: 150px;
    height: 100px;
    margin: 5px;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    padding: 40px;
    width: 500px;
  }
}

@media only screen and (max-width: 499px) {
  .cardWidth {
    width: 100px;
    height: 120px;
  }

  .cardContent {
    display: block;
    width: 100px;
    height: 100px;
    margin: 5px;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    padding: 40px;
    width: 300px;
  }

  .typography {
    font-size: 14px;
  }
}

.workouts {
  display: block;
  justify-content: center;
  align-items: center;
  white-space: pre;
  height: 400px;
  width: 65%;
}

.title {
  text-align: center;
  font-size: 20px;
  padding-top: 20px;
}

.title1 {
  text-align: center;
  font-size: 20px;
  padding-bottom: 22px;
}
.title2 {
  text-align: center;
  font-size: 20px;
}

.activities {
  display: block;
  justify-content: center;
  align-items: center;
  white-space: pre;
  height: 380;
  width: 1000;
}

.centering {
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button {
  align-items: center;
}
