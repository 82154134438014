.image {
  height: 300px;
  width: 300px;
  background-size: cover;
  margin-right: auto;
  margin-left: 250px;
  margin-top: 22px;
  display: flex;
  background-position-x: center;
  align-items: flex-end;
}

.container {
  padding: 40px;
}

.name {
  display: flex;
  font-size: 40px;
  width: 100%;
  height: 100%;
  
  color: black;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.justify {
  justify-items: center;
}

.fieldsetProp {
  border-color: #e7e7e7;
  align-items: left;
  height: auto;
  width: auto;
}

.fieldsetName {
  display: flex;
  align-items: left;
  font-size: 20px;
  color: black;
  width: auto;
  height: 30px;
}

.centering {
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
}
