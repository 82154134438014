.tableContainer {
  margin-bottom: 10px;
}

.table {
  /* cursor: pointer; */
  /* display: flex; */
  justify-content: center;
  min-height: 275;
}

.subTableCell {
  font-size: 0.9em !important;
  padding: 6px !important;
  border-bottom: none !important;
  width: 50px !important;
  display: block;
  text-align: center !important;
}

.weekdates {
  padding-bottom: 0px !important;
}

.personLink {
  color: black;
  text-decoration: none;
}

.personLink:hover {
  color: var(--primary-color);
}

.personCell {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

@media only screen and (min-width: 650px) {
  .smallScreen {
    display: none !important;
  }
}

@media only screen and (max-width: 649px) {
  .largeScreen {
    display: none !important;
  }
}

@media only screen and (max-width: 949px) {
  .show7 {
    display: none !important;
  }
}

@media only screen and (max-width: 899px) {
  .show6,
  .show7 {
    display: none !important;
  }
}

@media only screen and (max-width: 819px) {
  .show5,
  .show6,
  .show7 {
    display: none !important;
  }
}

@media only screen and (max-width: 699px) {
  .show4,
  .show5,
  .show6,
  .show7 {
    display: none !important;
  }
}

@media only screen and (max-width: 599px) {
  .show3,
  .show4,
  .show5,
  .show6,
  .show7 {
    display: none !important;
  }
}

@media only screen and (max-width: 499px) {
  .show2,
  .show3,
  .show4,
  .show5,
  .show6,
  .show7 {
    display: none !important;
  }
}

.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;

  color: black;
  width: 100%;
}

.team_logo {
  height: 50px;
  width: 50px;
  margin-left: auto;
  margin-right: auto;
}

.seeMore {
  text-align: right;
}
