.input {
  flex-basis: 100%;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1000px) {
  .cont {
    width: 100%;
    display: grid;
    grid-template-areas: "left right";
    grid-template-columns: 3fr 1fr;
    justify-content: space-around;
    text-align: center;
  }
  .sleepWeekCont {
    display: flex;
    margin: auto;
  }
  .sleepNightCont {
    display: flex;
    margin: auto;
    align-items: center;
  }

}

.left {
  grid-area: left;
}
.right {
  grid-area: right;
}

.container {
  padding: 40px;
}

.centering {
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
}

.margin {
  margin-left: 50px;
}

.heartrate {
  margin-top: 50px;
}

.justify {
  justify-items: center;
}
