.display {
  height: auto;
  width: auto;
  margin: auto;
}

.display p {
  width: 100%;
}

.display h2 {
  text-align: center;
}

.displayTitles {
  display: inline-block;
  width: 100%;
  padding-left: 5%;
  font-weight: bold;
}

.displayValues {
  display: inline-block;
  width: 100%;
  padding-right: 5%;
  text-align: end;
}

.displayValues p {
  width: 100%;
}

.avatarImage {
  font-size: 50px !important;
  height: 100px !important;
  width: 100px !important;
}

.image {
  height: 100px;
  width: 100px;
  background-size: cover;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  background-position-x: center;
  align-items: flex-end;
}
