.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2px;
  text-align: center;
}

.container p {
  font-size: 13px;
}

.logo {
  width: 50px;
  margin: auto;
  margin-right: 5px;
  margin-top: 5px;
}

.logos {
  display: flex;
  justify-content: space-around;
}

.link {
  composes: smallLink from "../../../style.css";
}

.link:hover {
  text-decoration: underline !important;
}
