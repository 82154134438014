@media only screen and (max-width: 900px) and (min-width: 400px) {
  .workoutInfoCont {
    grid-template-columns: 1fr;
    margin: auto;
  }

  .cont {
    grid-template-columns: 1fr;
    margin: auto;
  }
  .image {
    height: 365px;
    width: 365px;
    background-size: cover;
    margin-right: auto;
    margin-left: auto;
    margin-top: 122px;
    display: flex;
    background-position-x: center;
    align-items: center;
  }
  .name {
    display: flex;
    font-size: 40px;
    width: 100%;
    height: 100%;
    
    color: black;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
@media only screen and (max-width: 400px) {
  .workoutInfoCont {
    grid-template-columns: 1fr;
    margin: auto;
  }
  .cont {
    grid-template-columns: 1fr;
    margin: auto;
  }
  .image {
    height: 200px;
    width: 200px;
    background-size: cover;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    background-position-x: center;
    align-items: center;
  }
  .name {
    display: flex;
    font-size: 30px;
    width: 100%;
    height: 100%;
    margin-left: 50%;
    margin-right: 50%;
    
    color: black;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (min-width: 900px) {
  .workoutInfoCont {
    display: flex;
    margin: auto;
  }
  .cont {
    display: flex;
    margin: auto;
  }
  .image {
    grid-area: image;
    height: 365px;
    width: 365px;
    background-size: cover;
    margin-right: none;
    margin-left: none;
    margin-top: 122px;
    display: block;
    background-position-x: center;
    align-items: center;
  }
  .name {
    display: flex;
    font-size: 40px;
    width: 100%;
    height: 100%;
    
    color: black;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.container {
  padding: 40px;
}

.centering {
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
}

.justify {
  justify-items: center;
}
