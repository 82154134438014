.icon {
    font-size: 40px;
    width: 100%;
    height: 100%;
}

.button {
    text-align: center;
    border-radius: 20px!important;
}

.button:hover {
    background-color: transparent !important;
}

.container:hover {
    background-color: lightgrey;
    border: none;
    cursor: pointer;
}

.text {
    margin: 10px;
}

.container {
    margin-right: 7px;
    margin-left: 7px;
    text-align: center;
    display: block;
    width: 110px;
    height: 120px;
    border-radius: 10px;
}

@media only screen and (max-width: 680px) {
    .container {
        width: 90px;
        height: 105px;
        font-size: smaller;
    }
}

@media only screen and (max-width: 535px) {
    .container {
        width: 75px;
        height: 100px;
        font-size: x-small;
    }
}
