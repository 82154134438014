.row {
  display: flex;
  align-items: center;
}

.avatar {
  display: inline;
  margin-right: 6px;
}

@media only screen and (max-width: 599px) {
  .show {
    display: none !important;
  }
}
