.allContent {
  width: 60%;
  margin: auto;
}


.box {
  width: 70%;
  margin: auto;
  padding: 20px;
  position: relative;
  min-height: 500px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.overlayStyle {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff;
}

.button {
  position: relative;
  margin-top: 30px;
}

.input{
  position: relative;
  margin-top: 20px;
}

