.divider {
  width: 100%;
  height: auto;
  display: inline-block;
}

.passwordField {
  width: 80%;
  align-self: center;
}

.formLogin {
  margin: auto;
}

.name {
  font-weight: bold !important;
}

.theicons {
  float: right;
}

