@media only screen and (min-width: 500px) {
  .chart {
    width: 450px;
    height: 300px;
    /* margin-bottom: 80px; */
  }
}

@media only screen and (max-width: 499px) {
  .chart {
    width: 300px;
    height: 300px;
    /* margin-bottom: 80px; */
  }
}
