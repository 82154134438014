.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.table {
  margin: auto;
  table-layout: auto;
}

.graph {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.tableHeader {
  background-color: #8ee4af;
}

.noType {
  font-size: 16pt;
  padding: 25%;
  height: 250px;
}

.checkbox {
  padding-right: 0px !important;
}

.headerTitle {
  white-space: pre;
}
