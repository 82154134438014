.container {
    padding: 40px 60px 200px 60px;
}
  
.center {
    width: 100%;
    display: grid;
    /* grid-template-areas: "teamsList images";
    grid-template-columns: 3fr 2fr; */
    grid-template-areas: "paper";
    grid-template-columns: 1fr;
    justify-content: space-around;
    text-align: center;
}

.primaryColor {
    composes: primaryColor from "../../../style.css"; 
}
  
.paper {
    grid-area: paper;
}
  
.avatar {
  display: inline;
    margin-right: 6px;
}
  
.row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.row a {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
.header,
.title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: space-between;
    height: 50px;
    -ms-flex-align: center;
    align-items: center;
    
    padding-left: 20px;
    padding-right: 20px;
}
  
.header {
    font-size: 1.2rem;
}
  
.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0px 0px 0px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 799px) {
    .table td{
      font-size: 10px !important;
    }
}
  