.img {
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 1%;
}

.backgroundColor {
  background-color: #379683;
}

.card {
  width: 320px;
  height: 390px;
  padding-top: 5%;
  padding-left: 2%;
  padding-right: 2%;
  float: left;
  margin: 5px;
}
.gridLogin {
  width: 45%;
  height: 40%;
  padding-top: 1.5%;
  margin-top: 0%;
}

.gridIMG {
  width: 45%;
  height: 40%;
  padding-top: 1.5%;
  margin-top: 0%;
}
.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40%;
  padding-top: 1.5%;
  margin-top: 0%;
}
.passwordField {
  width: 75%;
  align-self: center;
}

@media only screen and (max-width: 1332px) {
  .img {
    width: 95%;
    height: 80%;
    align-items: center;
    margin-top: 75px;
  }

  .card {
    display: block;
    width: 230px;
    height: 350px;
    padding-top: 5%;
    float: left;
    margin: 5px;
  }
  .gridLogin {
    height: 40%;
    padding-top: 1.5%;
    margin-top: 0%;
  }

  .gridIMG {
    width: 40%;
    height: 40%;
    padding-top: 1.5%;
    margin-top: 0%;
  }

  .loginContainer {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40%;
    padding-top: 1.5%;
    margin-top: 0%;
  }

  .passwordField {
    width: 85%;
    align-self: center;
  }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
  .img {
    display: none;
  }

  .card {
    display: block;
    width: 300px;
    height: 355px;
    padding-top: 10px;
    float: middle;
    margin: 5px;
  }

  .gridLogin {
    width: 600px;
    height: 40%;
    padding-top: 1.5%;
    margin-top: 0%;
  }

  .gridIMG {
    width: 0;
    height: 0;
    padding-top: 1.5%;
    margin-top: 0%;
    margin-top: none;
    margin-bottom: 200px;
  }

  .loginContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40%;
    padding-top: 1.5%;
    margin-top: 0%;
  }

  .passwordField {
    width: 75%;
    align-self: center;
  }
}

@media only screen and (max-width: 600px) {
  .img {
    display: none;
  }

  .card {
    display: block;
    width: 300px;
    height: 355px;
    padding-top: 10px;
    float: middle;
    margin: 5px;
  }

  .gridLogin {
    width: 600px;
    height: 40%;
    padding-top: 1.5%;
    margin-top: 0%;
  }

  .gridIMG {
    width: 0;
    height: 0;
    /* padding-top: 1.5%;
    margin-top: 0%;
    margin-top: none;
    margin-bottom: 200px; */
  }

  .loginContainer {
    display: block;
    width: 300px;
    height: 40%;
    padding-top: 1.5%;
    margin-top: 0%;
  }

  .passwordField {
    width: 75%;
    align-self: center;
  }
}
.divider {
  width: 100%;
  height: auto;
  display: inline-block;
}

.formLogin {
  margin: auto;
}

.name {
  font-weight: bold !important;
}

.link {
  color: #379683;
}

.smallLink {
  color: gray;
  text-decoration: underline;
}
