.addUser {
    margin-top: 10px;
}
  
.modalContent {
    position: absolute;
    background-color: white;

    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 40px;
    padding: 10px;
}