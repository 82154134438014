.paper {
  width: 60%;
  margin: auto;
  background-color: #f5f5f5;
}

.container {
  /* text-align: center;
  justify-content: center; */
  margin: 20px 20%;
  /* margin-left: 20%;
  margin-right: 20%; */
  padding-top: 30px;
  padding-bottom: 30px;
}
.buttons {
  text-align: center;
  margin-top: 20px;
}

.inputField input {
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  box-sizing: border-box;
}

select {
  width: 100%;
  height: 30px;
  padding: 16px 20px;
  border: 1px solid black;
  border-radius: 3px;
  background-color: #ffffff;
  color: black;
}

.selectField {
  font-size: 14px !important;
  width: 100%;
  padding: 5px 10px;
  margin: 8px 0;
  position: relative;
  background-color: white;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 3px;
}

@media only screen and (max-width: 767px) {
  .paper {
    width: auto;
    margin: 1.5rem;
  }
}