.container {
  text-align: left;
  margin-left: 10%;
  margin-right: 10%;
}
/* .signInForm {
    width: 75%;
  } */

.inputField input {
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;

  box-sizing: border-box;
}
select {
  width: 100%;
  height: 30px;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
}
.signUpbtn {
  background-color: lightgreen;
}
