@media only screen and (min-width: 900px) {
  .center {
    width: 100%;
    display: grid;
    grid-template-areas: "teamsList images";
    grid-template-columns: 3fr 2fr;
    justify-content: space-around;
    text-align: center;
  }

  .imageContainer {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 899px) {
  .center {
    width: 100%;
    display: grid;
    grid-template-areas: "teamsList" "images";
    grid-template-columns: 1fr;
    justify-content: space-around;
    text-align: center;
  }

  .imageContainer {
    margin-top: 20px;
  }
}

.tableColor1 {
  background-color: white;
}

.tableColor2 {
  background-color: var(--primary-color-lighter);
}

.teamsList {
  grid-area: teamsList;
}

.imageContainer {
  grid-area: images;
}

.row {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.athleteWrapper {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.avatar {
  display: inline;
  margin-right: 6px;
}

.team_logo {
  height: 75px;
}

.chart {
  height: 350px;
  width: 350px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.readyness {
  height: 197px;
  width: 330px;
  padding: 10px;
}

.container {
  padding: 40px;
}

.header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: space-between;
  height: 50px;
  -ms-flex-align: center;
  align-items: center;

  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.2rem;
  height: 80px;
}

.subheader {
  padding: 15px;
  padding-bottom: 0px;
  font-size: 1.1rem;
  text-align: left;
}

.measurementHeader {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.downloadData {
  margin: 13px 10px 21px 10px;
  width: 800px;
}

.progressBar {
  margin-top: 15px;
  margin-bottom: 15px;
}

.button {
  text-align: left;
  padding: 15px;
}

.hidden {
  display: none;
}

.datePickerContainer {
  display: flex;
  justify-content: center;
  padding: 5px;
  margin-bottom: 10px;
}

.datePicker {
  padding: 5px;
}

.nameSelection {
  justify-content: center;
  margin-top: 20px;
}
