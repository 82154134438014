.card{
  margin: 15px;
  max-width: 700px;
  height: 80%;
  padding: 2%;
}

.text{
  font-size: 16px;
  text-align: justify;
  text-justify: auto;
  margin: 0%;
  line-height: 0.75cm;
  text-align: center;
}

.link {
  composes: smallLink from "../../style.css";
}

.link:hover {
  text-decoration: underline !important;
}