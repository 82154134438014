.divider {
  flex-grow: 1;
}

.datawell{
  margin: auto;
}

.spaceForNavBar {
  height: 82px;
}

