@media only screen and (min-width: 900px) {
  .center {
    width: 100%;
    display: grid;
    grid-template-areas: "teamsList images";
    grid-template-columns: 1fr;
    justify-content: space-around;
    text-align: center;
  }

  .imageContainer {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 899px) {
  .center {
    width: 100%;
    display: grid;
    grid-template-areas: "teamsList" "images";
    grid-template-columns: 1fr;
    justify-content: space-around;
    text-align: center;
  }

  .imageContainer {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 599px) {
  .show {
    display: none !important;
  }
}

.teamsList {
  grid-area: teamsList;
}

.imageContainer {
  grid-area: images;
}

.row {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.row a {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.avatar {
  display: inline;
  margin-right: 6px;
}

.team_logo {
  height: 75px;
}

.chart {
  height: 520px;
  width: 400px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 15px;
}

.statistics {
  height: 300px;
  width: 400px;
  padding: 10px;
  margin-bottom: 10px;
}

.sleep {
  height: 350px;
  width: 400px;
  padding: 10px;
  margin-bottom: 10px;
}

.container {
  padding: 40px;
}

.header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: space-between;
  height: 50px;
  -ms-flex-align: center;
  align-items: center;

  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.2rem;
  height: 80px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.settingsLink {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
}
