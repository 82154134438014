.card {
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 275;
  height: 200px;
  width: 200px;
}

.positionLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  
  color: black;
  width: 100%;
}

.team_logo {
  height: 50px;
  width: 50px;
  margin-left: auto;
  margin-right: auto;
}
