.row {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.row a {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.avatar {
  display: inline;
  margin-right: 6px;
}

.team_logo {
  height: 75px;
}

.container {
  padding: 40px;
}

.header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: space-between;
  height: 50px;
  -ms-flex-align: center;
  align-items: center;

  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.2rem;
  height: 80px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.search_box {
  display: flex;
  height: 70px;
  padding-left: 10px;
  padding-right: 10px;
}

.textField div p {
  display: inline-block;
  position: relative;
  top: -10px;
  padding-left: 5px;
}

.textField .searchIcon {
  display: inline-block;
  position: relative;
  top: -5px;
}
