/* Here are global css rules*/

body,
html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}

/* Same color as in pallette in App.js, duplicated here for an ease of use in components that are not from Material UI */
:root {
  --primary-color: #379683;
  --secondary-color: #05386b;
  --primary-color-lighter: #80bfb2;
  --secondary-color-lighter: a4cae0;
  --danger-color: #a05151;
  --background-color: #f5f5f5;
}

h1,
h2,
h4,
h3,
h5 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: normal;
}

input:focus {
  outline-color: var(--primary-color);
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: var(--primary-color);
}

::selection {
  color: white;
  background: var(--primary-color);
}
