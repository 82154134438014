/* Here are shared classes, in App.css are also some global styles.*/

/* Also possible to use for mixing inside other classes:     composes: nameTitle from "../../../style.css";   */

.nameTitle {
    font-family: 'Montserrat Alternates', sans-serif !important;
}

.smallLink{
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: none;
}

.smallLink, .smallLink:visited, .smallLink:active {
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: none;
}

.smallLink:hover {
    text-decoration: underline;
}

.primaryColor {
    color: var(--primary-color);
}

.DayPicker-TodayButton {
    color: var(--primary-color)!important;
  }
  
  /* Default modifiers */
  
  .DayPicker-Day--today {
    color: var(--primary-color) !important;
  }

.flexContainerCenter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.flexContainerCenterSpace {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.flexContainerVerticalStretch{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: stretch;
}

.fullBases {
    flex-basis: 100%;
    margin-bottom: 20px;
}

.fullWindowPaper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 99%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sleep_tooltip:after, .sleep_tooltip:before {
    right: 100%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.sleep_tooltip{
    padding: 5px 10px;
    display: inline-block;
    overflow: visible !important;
    white-space: normal !important;
    position: relative;
}

.good_sleep_tooltip {
    background: var(--secondary-color);
    color: #fff;
}

.bad_sleep_tooltip {
    background: var(--danger-color);
    color: #fff;
}

