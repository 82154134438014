
.title {
  text-align: center;
  padding-top: 100px;
}

.url {
  text-align: center;
  padding-top: 25px;
}

