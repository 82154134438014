@media only screen and (min-width: 600px) {
  .activities {
    display: block;
    justify-content: center;
    align-items: center;
    white-space: pre;
    height: 400px;
    width: 700px;
    align-items: center;
  }
  .title {
    text-align: center;
    font-size: 20px;
  }
  .pieSize {
    align-items: center;
    height: 370px;
    width: 100%;
  }

  .cardContainer {
    display: flex;
    align-items: center;
    width: 600px;
  }

  .cardContent {
    display: block;
    padding-left: 100px;
    width: 240px;
    height: 100px;
  }
}

@media only screen and (max-width: 599px) and (min-width: 400px) {
  .activities {
    display: block;
    justify-content: center;
    align-items: center;
    white-space: pre;
    height: 400px;
    width: 400px;
  }
  .title {
    text-align: center;
    font-size: 20px;
  }
  .pieSize {
    align-items: center;
    height: 250px;
    width: 100%;
  }
  .cardContainer {
    display: flex;
    align-items: center;
    width: 400px;
  }

  .cardContent {
    display: block;
    width: 200px;
    height: 100px;
    padding-left: 20px;
  }

  .cardWidth {
    width: 120px;
    height: 70px;
  }
}

@media only screen and (max-width: 399px) {
  .activities {
    display: block;
    justify-content: center;
    align-items: center;
    white-space: pre;
    height: 400px;
    width: 300px;
  }
  .title {
    text-align: center;
    font-size: 15px;
    
  }

  .pieSize {
    align-items: center;
    height: 300px;
    width: 100%;
  }
  .cardContainer {
    display: flex;
    align-items: center;
    width: 300px;
  }

  .cardContent {
    display: block;
    width: 200px;
    height: 100px;
  }

  .cardWidth {
    width: 120px;
    height: 70px;
  }
}

.root {
  max-width: 36ch;
  width: 100%;
}

.inline {
  display: inline;
}

.table {
  margin-top: 30px;
  margin-bottom: 50px;
  width: 100%;
}

.justify {
  justify-items: center;
}

.align {
  margin-left: 160px;
  margin-right: 160px;
}
