h1 {
    text-align: center;
}
.terms{
    text-align: center;
    margin-right: 250px;
    margin-left: 250px;
    background-color: rosybrown;
    font-size: 20px;
}

.title {
  word-break: break-all;
}

.card{
  margin: 15px;
  max-width: 700px;
  height: 80%;
  padding: 2%;
}

.text{
  font-size: 16px;
  text-align: justify;
  text-justify: auto;
  margin: 0%;
  line-height: 0.75cm;
}

.smallText {
  font-size: 11px;
  text-align: justify;
  text-justify: auto;
  margin: 0%;
  line-height: 0.75cm;
}