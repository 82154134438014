
.background_red, .background_yellow, .background_green, .background_grey {
  padding: 5px;
  margin-top: 25px;
  height: 150px;
  display: flex;
  align-content: space-evenly;
  flex-wrap: wrap;
}


.background_red {
  
  background-color: #ffd1d1;
}

.background_grey {
  
  background-color: #c2c2c2;
}

.team_link {
  color: black;
}

.background_yellow {
  
  background-color: #fceab8;
}

.background_green {
  
  background-color: #b2c2b3;
}

.hoverIcon {
  display: inline-block;
  border-radius: 60px;
  padding: 0.9em 1em;
  background-color: rgba(138, 137, 137, 0);
}

.hoverIcon:hover {
  cursor: pointer;
  display: inline-block;
  border-radius: 60px;
  padding: 0.9em 1em;
  background-color: rgba(0, 0, 0, 0.164);
}

.itemInfo {
  margin: auto;
}

.link {
  cursor: pointer;
  font-weight: bold;
}

.textInfo {
  padding: 15px;
  text-align: center;
}

