.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.modalContent {
  position: absolute;
  background-color: white;

  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 40px;
  padding: 10px;
}

.display {
  height: auto;
  width: auto;
  margin: auto;
}

@media only screen and (max-width: 599px) {
  .show {
    display: none !important;
  }
}
