.display {
  height: auto;
  width: auto;
  margin: auto;
}

.display p {
  width: 100%;
}

.display h2 {
  text-align: center;
}

.displayTitles {
  display: inline-block;
  width: 100%;
  padding-left: 5%;
  font-weight: bold;
}

.displayValues {
  display: inline-block;
  width: 100%;
  padding-right: 5%;
  text-align: end;
}

.displayValues p {
  width: 100%;
}

.image {
  height: 100px;
  width: 100px;
  background-size: cover;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  background-position-x: center;
  align-items: flex-end;
}

.avatarImage {
  font-size: 50px !important;
  height: 100px !important;
  width: 100px !important;
}

.container {
  padding: 40px 60px 200px 60px;
}

.paper {
  grid-area: paper;
}

.team_logo {
  width: 50px;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header {
  font-size: 1.2rem;
}

@media only screen and (max-width: 799px) {
  .show {
    display: none !important;
  }
}

.inputField input {
  width: 100%;
  padding: 10px 10px;
  margin: 8px 0;
  box-sizing: border-box;
}

.changePicture {
  display: block;
}
