.cont {
  display: grid;
  grid-template-columns: 1fr;
}

.table {
  height: 450px;
  width: 93vw;
}

.radarChart{
  height: 450px;
  width: 90vw;
  margin-bottom: 150px;
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.graph {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.checkbox {
  padding-right: 0px !important;
}

.headerTitle {
  white-space: pre;
}

.headerTitle:hover {
  background-color: grey;
  cursor: pointer;
}

@media only screen and (min-width: 1050px) {
  .cont {
    display: flex;
  }
  .table {
    height: 450px;
    width: 57vw;
  }
  .radarChart{
    height: 450px;
    width: 37vw;
    margin-left:5px;
  }
}
