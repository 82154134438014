@media only screen and (min-width: 900px) {
  .chart {
    width: 420px;
  }
}

@media only screen and (max-width: 499px) {
  .chart {
    width: 300px;
  }
}

@media only screen and (max-width: 899px) and (min-width: 500px) {
  .chart {
    width: 480px;
  }
}
