@media only screen and (min-width: 600px) {
    .cardContent {
      display: block;
      width: 170px;
      height: 150px;
    }
  
    .cardWidth {
      margin: 5px;
      width: 190px;
      height: 150px;
    }
  }
  
@media only screen and (max-width: 599px) {
    .cardContent {
        display: block;
        width: 150px;
        height: 100px;
    }

    .cardWidth {
        margin: 5px;
        width: 140px;
        height: 70px;
    }
}