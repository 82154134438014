@media only screen and (min-width: 600px) {
  .cardContainer {
    display: flex;
    align-items: center;
    width: 500px;
  }

  .cardContent {
    display: block;
    width: 150px;
    height: 100px;
  }

  .cardWidth {
    margin: 5px;
    width: 120px;
    height: 70px;
  }
}

@media only screen and (max-width: 599px) {
  .cardContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 250px;
  }

  .cardContent {
    display: block;
    width: 150px;
    height: 100px;
  }

  .cardWidth {
    margin: 5px;
    width: 120px;
    height: 70px;
  }
}

.activities {
  display: block;
  justify-content: center;
  align-items: center;
  white-space: pre;
  height: 380;
  width: 100%;
}

.title {
  text-align: right;
  font-size: 18px;
}

.table {
  margin-top: 30px;
  width: 100%;
}

.cell {
  text-align: center;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.justify {
  justify-items: center;
}

.container {
  padding: 40px;
}

.card {
  font-size: x-small;
}

.align {
  width: 100%;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}
