.containerAthlete {
  flex-grow: 1;
  /* max-height: 400px; */
}

.image {
  height: 100%;
  background-size: 50%;
  margin: 20px;
}

.imageContain {
  height: 100%;
  display: flex;
  justify-content: center;
}

.playerInfo {
  display: flex;
  /* width: 100%; */
}

.container {
  display: grid;
  /* justify-content: center; */
  text-align: center;
  margin: 20px;
}

.first {
  grid-area: first;
  margin-left: 10px;
  margin-bottom: 20px;
}

.second {
  grid-area: second;
}

.third {
  grid-area: third;
}

.info {
  margin: 20px;
  
}

@media only screen and (min-width: 1050px) {
  .container {
    grid-template-areas: "first second third";
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 730px) and (max-width: 1049px) {
  .container {
    grid-template-areas: "first second" "third third";
    grid-template-columns: 1fr 1fr;
  }
  .passwordChange {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 729px) {
  .container {
    grid-template-areas: "first" "second" "third";
    grid-template-columns: 1fr;
  }
  .containerAthlete {
    max-width: 400px;
    justify-content: center;
  }
  .passwordChange {
    margin-top: 10px;
  }
  .first {
    /* grid-area: info; */
    margin-left: -60px;
    margin-right: 55px;
  }
}
