.container {
  padding: 40px 60px 200px 60px;
}

.center {
  width: 100%;
  display: grid;
  /* grid-template-areas: "teamsList images";
  grid-template-columns: 3fr 2fr; */
  grid-template-areas: "paper";
  grid-template-columns: 1fr;
  justify-content: space-around;
  text-align: center;
}

.primaryColor {
  color: var(--primary-color);
}

.paper {
  grid-area: paper;
}

.team_logo {
  width: 50px;
}

.avatar {
  display: inline;
  margin-right: 6px;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header,
.title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: space-between;
  height: 50px;
  -ms-flex-align: center;
  align-items: center;

  padding-left: 20px;
  padding-right: 20px;
}

.button {
  text-align: left;
  padding: 15px;
}

.header {
  font-size: 1.2rem;
}

.title {
  font-size: 1rem;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0px 0px 0px;
  padding-bottom: 20px;
}

.modalContent {
  position: absolute;
  background-color: white;

  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 40px;
  padding: 10px;
}
.noEmailButton {
  /* background-color: white; */
  display: block;
  width: 160px;
  margin-left: 0px;
  padding-left: 0px;
}

@media only screen and (max-width: 799px) {
  .show {
    display: none !important;
  }
}

.settingsLink {
  /* margin-bottom: 10px; */
  display: flex;
  justify-content: space-around;
}
