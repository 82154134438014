.hoverIcon {
    display: inline-block;
    border-radius: 60px;
    padding: 0.9em 1em;
    background-color: rgba(138, 137, 137, 0);
  }
  
  .hoverIcon:hover {
    cursor: pointer;
    display: inline-block;
    border-radius: 60px;
    padding: 0.9em 1em;
    background-color: rgba(0, 0, 0, 0.164);
  }